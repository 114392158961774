/*
	-----------------------------------------
		Clearfix mixin
	-----------------------------------------
*/
%clearfix {
	&:after {
		content:"";
		display:table;
		clear:both;
	}
}

/*
	-----------------------------------------
		Retina mixin
	-----------------------------------------
*/

@mixin background-retina($file, $type, $width, $height) {
	background-image: url('../img/core/' + $file + '.' + $type);
	@media only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (-moz-min-device-pixel-ratio: 2),
	only screen and (-o-min-device-pixel-ratio: 2/1),
	only screen and (min-device-pixel-ratio: 2),
	only screen and (min-resolution: 192dpi),
	only screen and (min-resolution: 2dppx){
		& {
			background-image: url('../img/core/' + $file + '@2x.' + $type);
			-webkit-background-size: $width $height;
			-moz-background-size: $width $height;
			-o-background-size: $width $height;
			background-size: $width $height;
		}
	}
}