/*
-----------------------------------------
  Typography
-----------------------------------------
*/

body {
  @include typo-text;
  font-size: $fontsize-main;
  line-height: $line-height-main;
  color: $textcolor;
  letter-spacing: 0.025em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
  line-height: $line-height-main;
}

.lead {
  font-size: 18px;
  font-weight: 300;
  p {
    @extend .lead;
  }

  &--subtle {
    color: $graymedium;
    p { @extend .lead--subtle; }
  }

  @media screen and (min-width: $tab) {
    font-size: 20px;
  }
}

/*
  Intro class ...
 */

.intro, .intro p {
  font-size: 18px;
  font-weight: 300;
  color: $graymedium;
  @media screen and (min-width: $tab) {
    font-size: 20px;
  }
}

strong, th, thead td {
  font-weight: $fontweight-bold;
}

h1, h2, h3, h4, h5, h6,
.ash1, .ash2, .ash3, .ash4, .ash5, .ash6 {
  @include typo-header;
  line-height: 1.25em;
  display: block;
  margin-bottom: .65em;
}

// ...
h1, h2, h3, h4, h5, h6 {
  &:not([class]),
  &[class=""] {
    a {
      color: $textcolor;
      border-bottom-color: transparent;
    }
  }
}

h1 {
    &:not([class]),
    &[class=""] {
        a{
            color: $linkcolor;
            &:hover, &:focus {
                color: $hover;
            }
        }
    }
}

h1, .ash1 {
  font-size: $fontsize-h1-small;
  @media screen and (min-width: $note) {
    font-size: $fontsize-h1;
  }
}

h2, .ash2 {
  font-size: $fontsize-h2-small;
  @media screen and (min-width: $note) {
    font-size: $fontsize-h2;
  }
}

h3, .ash3 {
  font-size: $fontsize-h3-small;
  @media screen and (min-width: $note) {
    font-size: $fontsize-h3;
  }
}

h4, .ash4 {
  font-size: $fontsize-h4-small;
  @media screen and (min-width: $note) {
    font-size: $fontsize-h4;
  }
}

h5, .ash5 {
  font-size: $fontsize-h5;
  text-transform: uppercase;
}

h6, .ash6 {
  font-size: $fontsize-h6;
}

/*
-----------------------------------------
  Links
-----------------------------------------
*/

a {
  text-decoration: none;
  color: $linkcolor;
  @include transition(all .25s ease-out);

  &:hover, &:focus {
    color: $hover;
  }
  &:focus {
    outline: none;
  }

  &:not([class]),
  &[class=""] {
    border-bottom: 1px solid $linkcolor;
    &:hover, &:focus {
      border-color: $hover;
    }
  }
}

figure a {
  &:link, &:visited, &:hover, &:active, &:focus {
    border: none;
  }
}

ul, ol{
  padding: 0;
  margin: 0 0 1.5em;
  list-style: none;
  line-height: $line-height-main;
  ul, ol { font-size: 1em; margin-bottom: 0; }
}

/*
-----------------------------------------
  Quote
-----------------------------------------
*/

blockquote {
  font-weight: 600;
  margin-bottom: 1.5em;
  p {
    padding-left: 2em;
    margin-bottom: 0;
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 5%;
      height: 90%;
      width: 4px;
      background-color: $maincolor;
    }
    &:before {
      left: 0;
    }
    &:after {
      left: 10px;
    }
  }
}

/*
-----------------------------------------
  ... Basic elements
-----------------------------------------
*/

table { border-left: 1px solid #ccc; border-top: 1px solid #ccc; }
table tr td { border: 1px solid #ccc; border-left: none; border-top: none; padding: 4px; }
table tr th { border: 1px solid #ccc; border-left: none; border-top: none; padding: 4px; }
code { font-family: Monaco, "Courier New", monospace; }
pre { font-size: 13px; line-height: 18px; background: #F5F5F5; margin: 0 0 12px 0; padding: 10px; }
cite, .rt em { font-style: italic; }

hr { background: none; height: 1px; width: 100%; border: none; border-top: 1px solid #ccc; }

img[align="left"] { float: left; padding: 0 10px 10px 0; }
img[align="right"] { float: right; padding: 0 0 10px 10px; }

/*
-----------------------------------------
  RTE
  ... WIP
-----------------------------------------
*/

.rte {

  // Margin for heading tags -> only in .rte
  h1, h2, h3, h4, h5, h6,
  .ash1, .ash2, .ash3, .ash4, .ash5, .ash6 {
    margin: $line-height-main 0 .65em;
    &:first-child {
      margin-top: 0;
    }
  }

  // Margin for p -> only in .rte
  p {
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }

  // Hide horizontal rules (used for array splits) (
  hr { display: none; }
  
  // ul
  ul{
     list-style-type: disc;
     li{
         margin-left: 15px;
     }
  }
  
  // ul
  ol{
     list-style-type: decimal;
     li{
         margin-left: 15px;
     }
  }
}

/*
  Icons
 */

.material-icons {
  font-size: 21px;
}
