/*
	-----------------------------------------
	Header
	-----------------------------------------
*/

.header {

  @media screen and (max-width: $tab+1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110px;
    z-index: 1000; //9000;
    background-color: $white;

    &:not(.header--transparent) + * {
      padding-top: 110px;
    }

    .container,
    .equal { height: 100%; }
  }

  @media screen and (max-width: $tab) {
    height: 110px;

    &:not(.header--transparent) + * {
      padding-top: 110px;
    }
  }

  .layout {
    &__item {
      &:first-child {
        position: relative;
        z-index: 9050;
        @media screen and (max-width: $tab) {
          padding-left: 0;
        }
        
      }
      &:last-child {
        margin-left: auto;
        align-self: center;
        @media screen and (max-width: $tab) {
          padding-right: 0;
        }
      }
    }
  }

  /*
    Transparent header
    (on Home)
   */
  &.header--transparent:not(.header--fixed) {
    @media screen and (max-width: $tab) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: transparent;
      .logo {
        &__svg {
          .st0 {
            fill: $white;
          }
        }
      }
      .nav {
        .hasfold:after {
          color: $white;
        }
        ul {
          ul {
            background-color: transparent;
          }
        }
        li {
          &.nav__icon {
            a {
              border-bottom-color: transparent;
            }
          }
        }
        // a {
        //   color: $white;
        //   border-bottom: 1px solid $white;
        //   &:hover, &:focus {
        //     color: $black;
        //     border-bottom-color: $black;
        //   }
        // }
        &__icon {
          span {
            color: $black;
            background-color: transparent;
            &:before {
              background-color: $black;
            }
          }
        }
      }
      .nav-toggle:not(.active){
        span {
          background: white;
          &:before, &:after {
            background: white;
          }
        }
      }
    }

    .lang {
      &__label {
        background-color: transparent;
        //color: $white;
      }
    }
  }

  /*
    Fixed header
   */
  &.header--fixed {

    @media screen and (min-width: $tab) {
      position: fixed;
      z-index: 9999;
      width: 100%;
      box-shadow: 0 0 10px rgba($black, 0.1);
      height: 100px;
      animation: slide-down .7s;
      background: white;
      .logo {
        &__text {
          display: none;
        }
        &__link {
          display: block;
          float: left;
          position: relative;
          //top: -2px;
        }
      }

      .tab_3 {
        width: 33.33%;
      }
      .tab_twothird {
        width: 66.66%;
      }

      .nav {
        float: right;
        position: relative;
        top: -2px;
        ul {
          margin-left: -8px;

          /* Sub nav */
          ul {
            li { margin-left: 0; }
          }
        }
        li {
          margin-left: 8px;
        }
        &__icon {
          display: none;
        }
      }
    }

    @media screen and (min-width: $desk) {
      .nav {
        ul {
          margin-left: -20px;
        }
        li {
          margin-left: 20px;
        }
      }
    }

    @media screen and (min-width: $note) {
      .nav {
        &__icon {
          display: block;
        }
      }
    }
  }

  /*
    Language selector
   */
  .lang {
    @media screen and (min-width: $tab) {
      position: relative;
      top: -3px;
      margin: 0 0 0 auto;

      &__label {
        text-transform: uppercase;
        display: block;
        text-align: right;

        span {
          border-bottom: none;
          font-size: 12px;
          @media screen and (min-width: $note) {
            font-size: 16px;
          }
          @media screen and (min-width: 1400px) {
            font-size: 18px;
          }
        }
      }
      &__list {
        li {
          float: none;
          margin-top: 0;
        }
      }
      @media screen and (min-width: $note) {
        top: 3px
      }
      @media screen and (min-width: 1400px) {
        top: 2px;
      }
    }

    &__label {
      i {
        @media screen and (min-width: $tab) {
          display: none;
        }
      }
      span {
        display: inline-block;
        span {
          &:first-child {
            @media screen and (min-width: $tab) {
              display: none;
            }
          }
          &:last-child {
            display: none;
            @media screen and (min-width: $tab) {
              display: block;
            }
          }
        }
      }
    }
  }
}

@keyframes slide-down {
  0% {
    opacity: 0.8;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.logo {
  font-size: 21px;
  margin-bottom: 0;

  @media screen and (min-width: $desk) {
    font-size: 24px;
  }
  @media screen and (min-width: $note) {
    font-size: 28px;
  }
  @media screen and (min-width: $cine) {
    font-size: 32px;
  }

  &__link {
    border-bottom: none;
    display: block;
    padding: 15px 0;

    &:hover, &:focus {
      .svg__title .st0 {
        fill: darken($linkcolor, 5%);
      }
      .svg__baseline .st0 {
        fill: $titlecolor;
      }
    }
    @media screen and (min-width: $mob+1) {
      padding: 0;
    }
    @media screen and (min-width: $tab) {
      //display: none;
    }
  }
  &__svg {
    position: relative;
    z-index: 1000;
    display: block;
    width: 130px;

    &.active {
      .st0 {
        fill: $titlecolor!important;
      }
    }

    @media screen and (min-width: $land) {
      width: 150px;
    }

    .st0 {
      fill: $linkcolor;
      @include transition(all .4s ease-in);
    }

    /* baseline svg group*/
    .svg__baseline {
      .st0 {
        &:nth-child(2) {
          @include transition-delay(.05s);
        }
        &:nth-child(3) {
          @include transition-delay(.1s);
        }
        &:nth-child(4) {
          @include transition-delay(.15s);
        }
        &:nth-child(5) {
          @include transition-delay(.2s);
        }
        &:nth-child(6) {
          @include transition-delay(.25s);
        }
        &:nth-child(7) {
          @include transition-delay(.3s);
        }
        &:nth-child(8) {
          @include transition-delay(.35s);
        }
        &:nth-child(9) {
          @include transition-delay(.4s);
        }
        &:nth-child(10) {
          @include transition-delay(.45s);
        }
        &:nth-child(11) {
          @include transition-delay(.5s);
        }
        &:nth-child(12) {
          @include transition-delay(.55s);
        }
      }
    }
  }
  &__text {
    display: none;
    a {
      white-space: nowrap;
      font-weight: 400;
    }
    @media screen and (min-width: $tab) {
      //display: block;
    }
  }
}

.nav {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  @media screen and (max-width: $mob) {
    padding: 100px 15px 15px;
  }
  @media screen and (max-width: 768px) {
    @include transition(all 250ms ease-in-out);
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1000;
    padding: 110px 30px 30px;
    opacity: 0;
    float: none;
    background-color: $lightbg;
  }

  &.shown {
    top: 0;
    opacity: 1;
  }

  ul {
    @extend %clearfix;
    margin-bottom: 0;

    @media screen and (min-width: $tab) {
      margin-left: -10px;
    }
    @media screen and (min-width: $note) {
      margin-left: -20px;
    }

    /* Sub nav */
    ul {
      z-index: 1;
      padding: 20px 20px 0;
      background-color: $white;
      min-width: calc(100% + 40px);

      transform-origin: top center;
      @include transition(all .2s ease-out);

      li {
        margin: 0 0 .8em;
        clear: left;
        @include transition(all .2s ease-out);
        &:nth-child(1) {
          @include transition-delay(.1s);
        }
        &:nth-child(2) {
          @include transition-delay(.2s);
        }
        &:nth-child(3) {
          @include transition-delay(.3s);
        }
        &:nth-child(4) {
          @include transition-delay(.4s);
        }
        &:nth-child(5) {
          @include transition-delay(.5s);
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        font-size: 16px;
      }

      &:hover {
        opacity: 1;
        @include transform(rotateX(0));
        li {
          opacity: 1;
          @include transform(translateX(0));
        }
      }

      @media screen and (min-width: $tab) {
        position: absolute;
        opacity: 0;
        padding-bottom: 20px;
        @include transform(rotateX(-90deg));
        li {
          opacity: 0;
          @include transform(translateX(-10px));
        }
      }
    }
  }

  li {
    margin-bottom: 20px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }
    &:before {
      display: none;
    }

    @media screen and (min-width: $tab) {
      float: left;
      margin: 5px 0 0 10px;
      &:not(.nav__icon) + .nav__icon {
        margin-left: 2em;
      }
    }
    @media screen and (min-width: $note) {
      margin-left: 20px;
    }

    /*&:not([class]),
    &[class=""] {
      + .nav__icon {
        clear: left;
        @media screen and (min-width: $note) {
          clear: none;
        }
      }
    }*/
  }

  .active > a {
    color: $titlecolor;
    border-bottom-color: $titlecolor;
  }

  a {
    display: inline-block;
    font-size: 18px;
    border-bottom: 1px solid $linkcolor;
    line-height: 1.2em;

    &:hover, &:focus {
      color: $titlecolor;
      border-bottom-color: $titlecolor;

      + ul {
        opacity: 1;
        @include transform(rotateX(0));
        li {
          opacity: 1;
          @include transform(translateX(0));
        }
      }
    }

    @media screen and (min-width: $tab) {
      display: block;
      font-size: 12px;
    }
    @media screen and (min-width: $note) {
      font-size: 16px;
    }
    @media screen and (min-width: 1400px) {
      font-size: 18px;
    }
  }

  // Has sub nav
  @media screen and (min-width: $tab) {
    .hasfold {
      margin-right: 20px;
      position: relative;
      &:after {
        content: "\f078";
        font: normal normal normal .5em/1 FontAwesome;
        text-rendering: auto;
        pointer-events: auto;
        position: absolute;
        top: 0;
        left: 100%;
        height: 100%;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  // Nav icon
  &__icon {
    display: none;
    a {
      position: relative;
      border-bottom: none;
      &:hover, &:focus {
        span {
          opacity: 1;
          z-index: 1;
          @include transform(translateX(-50%) translateY(0));
        }
      }
    }
    span {
      display: block;
      position: absolute;
      top: 100%;
      left: 50%;
      color: $linkcolor;
      background-color: $white;
      padding-top: 15px;
      font-size: 12px;
      white-space: nowrap;
      opacity: 0;
      @include transform(translateX(-50%) translateY(100%));
      @include transition(all .25s ease-in);

      &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 50%;
        width: 1px;
        height: 10px;
        margin-left: -1px;
        background-color: $linkcolor;
      }
    }
    @media screen and (min-width: $tab) {
      &--tab {
        display: block;
      }
    }
    @media screen and (min-width: 840px) {
      &--desk {
        display: block;
      }
    }
    @media screen and (min-width: 1120px) {
      &--land {
        display: block;
      }
    }
    @media screen and (min-width: $note) {
      &--note {
        display: block;
      }
    }
  }

  @media screen and (max-width: 768px) {
    > ul {
      position: relative;
      > li > a {
        font-size: calc(.75rem + 3vw);
        font-weight: 300;
      }
    }
    ul ul {
      background-color: transparent;
    }
    a {
      color: $textcolor;
      &:hover, &:focus {
        color: $hover;
        border-color: $hover;
      }
    }
    li:not(.active) a {
      border-color: transparent;
    }
  }
  /*.lang {
    .lang__label {
      display: inline-block;
      span {
        margin-left: 0;
      }
    }
  }*/
}

/*
  Hamburger link
 */

.nav-toggle {
  background: none;
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  border: none;
  z-index: 1020;
  cursor: pointer;
  padding: 20px 35px 22px 10px;
  margin-top: -32px;

  @media screen and (min-width: $mob+1) {
    right: 50px
  }
  @media screen and (min-width: $tab) {
    display: none;
  }

  span, span:before, span:after {
    @include transition(all 320ms ease-in-out 0ms);
    cursor: pointer;
    border-radius: 1px;
    height: 3px;
    width: 25px;
    background: $titlecolor;
    position: absolute;
    display: block;
    content: '';
  }
}

/*
  Hamburger icon
 */

.nav-toggle {
  span {
    &:before {
      top: -8px;
    }
    &:after {
      bottom: -8px;
    }
  }
  &.active {
    span {
      background-color: transparent;
      &:before,
      &:after {
        top: 0;
        background: $titlecolor;
      }
      &:before {
        @include transform(rotate(45deg));
      }
      &:after {
        @include transform(rotate(-45deg));
      }
    }
  }
}

.mobile-socials {
  margin: -5px;
  div {
    margin: 5px;
  }
  @media screen and (min-width: $tab) {
    display: none;
  }

  &__item {
    a {
      border: none;
      font-weight: 300;
      i {
        color: $linkcolor;
        &.fa-facebook {
          margin: 0 13px 0 5px;
        }
        &.fa-instagram {
          margin: 0 10px 0 3px;
        }
      }
      .highlight {
        color: $linkcolor;
        text-decoration: underline;
      }
    }
  }
}

.mobile-info {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: .875rem;
  margin: 30px -10px -10px;
  div {
    margin: 10px;
  }
  a {
    font-size: .875rem;
  }
  p {
    line-height: 1.2;
  }
  @media screen and (min-width: $tab) {
    display: none;
  }
}

.mobile-image {
  display: flex;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  pointer-events: none;
  align-items: center;
  @media screen and (min-width: $tab) {
    display: none;
  }
  img {
    display: block;
    width: 100%;
    // max-width: 220px;
    // margin-top: 60px;
    @media screen and (min-width: 480px) {
      // max-width: 260px;
      // margin-top: 15px;
    }
  }
}