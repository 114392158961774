/*
	-----------------------------------------
	Variables
	-----------------------------------------
*/

/* fonts */
$fontfamily-header:   'Source Sans Pro', sans-serif;
$fontweight-header:   300;

$fontfamily-text:     'Source Sans Pro', Arial, Helvetica, sans-serif;
$fontweight-text:     400;

$fontweight-bold:     700;

$fontsize-main:       16px;
$line-height-main:    1.65em;

@mixin typo-header() {
	font-family: $fontfamily-header;
	font-weight: $fontweight-header;
}

@mixin typo-text() {
	font-family: $fontfamily-text;
	font-weight: $fontweight-text;
}

/* font-sizes */
$fontsize-h1: 40px;
$fontsize-h2: 36px;
$fontsize-h3: 26px;
$fontsize-h4: 24px;
$fontsize-h5: 20px;
$fontsize-h6: 16px;

$fontsize-h1-small: 28px;
$fontsize-h2-small: 26px;
$fontsize-h3-small: 24px;
$fontsize-h4-small: 21px;

/* colors */
$white:       #fff;
$graylight:   #d3d2d2;
$graymedium:  #666; //#918f8f;
$graydark:    #231F20;
$black:       #000;

$textcolor:   $graydark;
$maincolor:   #94B8C9;
$linkcolor:   $maincolor;
$hover:       darken($linkcolor, 35%);
$titlecolor:  $graydark;
$altcolor:    hotpink;
$bulletcolor: $maincolor;
$lightbg:     #f4f8f9;

/* breakpoints */
$hd: 1920px;
$cine: 1650px;
$note: 1280px;
$land: 1024px;
$desk: 960px;
$tab: 769px; // Normally 768px, but +1px because colstructure uses 768px as max-width breakpoint
$stab: 767px;
$mob: 680px;
$small: 400px;

/* selection */
::-moz-selection {background: #F4F4F4; color: $black;}
::selection {background: #F4F4F4; color: $black;}
