//	-----------------------------------------
//  Project name: Victors
//  Company: Lavagraphics
//  Author: Benoît Blancquaert
// -----------------------------------------

@import "compass/css3"; /* compass plugin for prefix fixes */

@import "partials/normalise"; /* Normalise */
@import "partials/mixins"; /* handy mixins */
@import "partials/vars"; /* fonts and vars */
@import "partials/plugins"; /* plugin styles */

@import "partials/typography"; /* fonts and vars */

/*
	-----------------------------------------
	General page structure and layout
	-----------------------------------------
*/

#wrapper {
  width: 100%;
  margin: 0 auto;
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  padding: 0 30px;


  @media screen and (min-width: $desk) {
    padding: 0 90px;
  }
  @media screen and (min-width: $cine) {
    padding: 0;
  }
}

@import "partials/animations";
@import "partials/header";
@import "partials/content";
@import "partials/forms";

@import "partials/button";
@import "partials/helpers";

/*
	-----------------------------------------
	Fixes and Test classes
	-----------------------------------------
*/

html.ie {
  // IE 6-9
  &.ie8, &.ie7 {
    #wrapper {width: 960px;}
  }
}

.clear {
  clear: both;
}