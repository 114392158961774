/*main {
  width: 100%;
  overflow: hidden;
}*/

/*
	-----------------------------------------
	General
	-----------------------------------------
*/

.darkbg {
	background: $maincolor;
	color: $white;
}

.lightbg {
  background-color: $lightbg;
}

.altbg {
  background-color: $linkcolor;
  color: $white;
  h2, h3, h4 {
    color: $white;
  }
}

#gmap {
  width: 100%;
  height: 400px;
}

#subForm {
	position: relative;
	button {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
	}
}

.popup-video {
  position: relative;
  
  i {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 6.5em;
    color: $white;
    @include transition(all .125s ease-out);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
  }
  .visual + i {
    @media screen and (min-width: $land) {
      width: calc(100% + 30px);
    }
    @media screen and (min-width: $cine) {
      width: calc(100% + 90px);
    }
  }

  @media screen and (min-width: $tab) {
    &:hover, &:focus, &:active {
      i {
        font-size: 7em;
      }
    }
  }
}

/*
	-----------------------------------------
	Footer
	-----------------------------------------
*/

footer {
  border-top: 1px solid #94B8C9;
}

/*
  Icon list
 */
.icons {

  li {
    position: relative;
    @media screen and (min-width: $mob+1) {
      padding-left: 2em;
    }
  }
  a {
    border-bottom: none;
    &:hover, &:focus {
      span {
        border-bottom-color: $titlecolor;
      }
    }
  }
  i {
    position: absolute;
    top: 2px;
    left: 0;
    color: $linkcolor;
    display: none;
    @media screen and (min-width: $mob+1) {
      display: inline-block;
    }
  }
  span {
    border-bottom: 1px solid $linkcolor;
  }

  /* ... */
  .fa {
    top: 5px;
    left: 2px;
    font-size: 18px;
  }
  .fa-facebook {
    left: 4px;
  }
}

/*
  Company link
 */

a.lava {
  display: block;
  background: url(https://resources.lavagraphics.be/signature/credits.png) no-repeat; /* -negative for negative version */
  width: 14px;
  height: 17px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  text-indent: -9999px;
  overflow: hidden;
  border-bottom: none;
  opacity: 0.2;
  z-index: 1000;
  &:hover, &:focus {
    opacity: .5;
  }
  @media screen and (max-width: $mob) {
    display: none;
  }
}

/*
	-----------------------------------------
	Components
	-----------------------------------------
*/

/*
  Card
 */

.card {
  display: block;
  color: $graymedium;
  @include transition(none);

  &__visual {
    display: block;
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;

    img {
      display: block;
      width: 100%;
      @include transition(all .3s ease-out);
    }
  }
  &__content {
    display: block;
    h3 {
      color: $graydark;
    }
    span {
      color: $linkcolor;
      border-bottom: 1px solid $linkcolor;
      @include transition(all .3s ease-out);
    }
  }

  &:hover, &:focus {
    img {
      opacity: .95;
      @include transform(scale(1.1));
    }
    span {
      color: $graydark;
      border-bottom-color: $graydark;
    }
  }
}

/*
  Visual
 */

.visual {
  display: block;
  position: relative;
  width: 100%;
  @media screen and (min-width: $land) {
    width: calc(100% + 30px);
    max-width: none;
  }
  @media screen and (min-width: $cine) {
    width: calc(100% + 90px);
  }

  &--portrait {
    @media screen and (min-width: $land) {
      width: auto;
      height: 100Vh;
      max-height: 60vh;
      margin-left: auto;
    }
    @media screen and (min-width: $land) and (min-height: 980px) {
      max-height: 75vh;
    }
    @media screen and (min-width: $cine) {
      width: auto;
    }
  }

  img {
    display: block;
    width: 100%;
    max-width: none;
  }

  &__caption {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    color: $white;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    h2, p {
      position: relative;
      z-index: 1;
    }
    h2 {
      color: $white;
      margin-bottom: .3em;
      @include transition(all .4s ease-in .3s);
      @include transform(translateY(calc(.3em + 52px)));
    }
    p {
      opacity: 0;
      margin-bottom: 0;
      line-height: 1.5em;
      @include transition(all .4s ease-in);
    }
    a {
      color: $white;
      border-bottom-color: $white;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      @include transition(opacity .3s ease-in);

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+60,000000+100&0+0,0.6+60,0.6+100 */
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 60%, rgba(0,0,0,0.6) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 60%,rgba(0,0,0,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 60%,rgba(0,0,0,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#99000000',GradientType=0 ); /* IE6-9 */
    }
  }

  &:hover {
    .visual__caption {
      &:before {
        opacity: 0.75;
      }
      h2 {
        @include transition(all .4s ease-out);
        @include transform(translateY(0));
      }
      p {
        opacity: 1;
        @include transition(all .4s ease-in .3s);
      }
    }
  }
}

/*
  Square image/link
 */
.square {
  padding-bottom: 100%;
  position: relative;
  display: block;
  overflow: hidden;
  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    @include transition(all .25s ease-out);
  }
  &:hover, &:focus {
    .square__inner {
      @include transform(scale(1.1));
    }
  }
}

/*
  Pagination
 */

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;

  &__list {
    margin: 0;
    li {
      display: inline-block;
      margin: 0 .4em;
      @media screen and (min-width: $tab) {
        margin: 0 .8em;
      }
    }
    .active {
      a { color: $hover; }
    }
    a {
      border-bottom: none;
    }
  }

  a {
    border-bottom: none;
    &.pagination__right {
      &:hover, &:focus {
        img {
          @include transform(translateX(5px));
        }
      }
    }
    &.pagination__left {
      &:hover, &:focus {
        img {
          @include transform(translateX(-5px));
        }
      }
    }
  }
  img {
    @include transition(all .25s ease-out);
  }
  span {
    color: rgba($linkcolor, .35);
  }
}

/*
  Filter navigation
 */

.filter-nav {

  margin-bottom: 15px;

  &__inner {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    span {
      text-transform: uppercase;
    }
  }

  .select {
    margin-top: 15px;
  }

  @media screen and (min-width: $tab) {
    &__inner {
      flex-direction: row;
      align-items: center;
    }
    .select {
      margin-top: 0;
    }
  }
  @media screen and (min-width: $desk) {
    position: absolute;
    top: -8px;
    right: 90px;
    margin-bottom: 0;

    &__inner {
      float: right;
    }
  }
  @media screen and (min-width: $note) {
    top: 0;
  }
  @media screen and (min-width: $cine) {
    right: 0;
  }
}

/*
  Side navigation (< prev - next >)
 */

.side-nav {
  display: none;
  text-align: center;

  &__prev,
  &__next {
    display: inline-block;
    background-color: $graylight;
    color: $white;
    padding: 10px 15px;
    margin: 0 5px;

    &:hover, &:focus {
      color: $white;
      background-color: darken($graylight, 10%);
    }
  }

  @media screen and (min-width: $tab) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &__prev,
    &__next {
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -50px;
      z-index: 1;
      height: 100px;
      width: 40px;
      font: normal normal normal 21px/1 FontAwesome;
      text-rendering: auto;
      pointer-events: auto;
      padding: 0;
      margin: 0;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        line-height: 100px;
      }

      &:hover, &:focus {
        color: $white;
        background-color: darken($graylight, 10%);
      }
    }

    &__prev {
      left: 0;
      &:before {
        content: "\f177";
        text-align: right;
      }
    }
    &__next {
      right: 0;
      &:before {
        content: "\f178";
      }
    }

    span {
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
    }
  }
}

/*
  Addthis container
 */

.addthis-container {
  @extend %clearfix;
  margin-top: 1.5em;
  p {
    position: relative;
    top: .35em;
    padding-right: 1em;
    margin-bottom: .5em !important;
    text-transform: uppercase;
  }
}

/*
  Columns
 */

@media screen and (min-width: $tab) {
  .columns {
    column-count: 2;
    column-gap: 60px;

    h1, h2 {
      column-span: all;
    }
    blockquote {
      column-fill: auto;
    }
  }
}

/*
  Figure caption
 */

.figure {
  position: relative;

  &__caption {
    span {
      display: block;
      font-size: 14px;
      font-style: italic;
      line-height: 120%;
    }
  }

  /*@media screen and (min-width: $tab) {
    &--landscape {
      .figure__caption {
        width: calc(100% - 15px);
      }
    }
  }*/
}

 /*
    Cookie notice
   */
.cookie-notice {
    font-size: 14px !important;
    z-index: 9999;
    background-color: #fff;
    position: fixed;
    box-shadow: 0 0px 10px rgba($black, 0.1);
    width: 100%;
    animation: slide-up 0.7s;
    bottom: 0;
    left: 0;
}

@keyframes slide-up {
  0% {
    opacity: 0.8;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*
  Parallax
 */

.parallax-window {
  min-height: 480px;
  background: transparent;
}

/*
  Back to top ^
 */

.back-to-top {
  display: block;
  position: absolute;
  top: -5px;
  right: 0;
  font-size: 42px;
  padding: 15px;
  cursor: pointer;

  span {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
  }

  &:hover, &:focus {
    color: darken($linkcolor, 10%);
    @include transform(translateY(-10px));
  }

  @media screen and (min-width: $desk) {
    top: 15px;
    right: 45px;
  }
}

/*
  Language navigation
 */

.lang {
  position: relative;
  display: inline-block;
  margin-top: 1.5em;

  &__label {
    position: relative;
    cursor: default;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 300;
    &:after {
      content: ":";
    }
    > span { margin-left: 2em; }
  }

  &__list {
    display: inline-block;
    padding-left: .5em;
    font-weight: 300;

    li {
      display: inline-block;
      margin-bottom: 10px;
      @include transition(all .2s ease-in);

      a {
        color: $linkcolor;
        text-decoration: underline;
      }

      + li {
        margin-left: .15em;
      }
      &:nth-child(1) {
        @include transition-delay(.1s);
      }
      &:nth-child(2) {
        @include transition-delay(.2s);
      }
      &:nth-child(3) {
        @include transition-delay(.3s);
      }
    }

    @media screen and (min-width: $tab) {
      position: absolute;
      top: 0;
      left: 100%;
      white-space: nowrap;
    }
  }

  i {
    position: absolute;
    top: 2px;
    left: 0;
    color: $linkcolor;
  }

  &:hover, &:active, &.is-active {
    .lang__list {
      li {
        opacity: .8;
        @include transform(translateX(0));
      }
    }
  }

  @media screen and (min-width: $tab) {
    &__label {
      color: $linkcolor;
      background-color: $white;
      &:after {
        content: none;
      }
      span {
        border-bottom: 1px solid $linkcolor;
      }
    }
    &__list {
      li {
        opacity: 0;
        @include transform(translateX(-5px));
      }
    }
  }
}

/*
  Dropdown title
 */

.dd-title {
  position: relative;
  z-index: 1;

  > span {
    display: inline-block;
    position: relative;
  }

  &__wrap {
    color: $linkcolor;
    border-bottom: 2px solid $linkcolor;
    margin-right: 30px;

    &:hover, &:active {
      ul {
        opacity: 1;
        @include transform(rotateX(0));
      }
      li {
        opacity: 1;
        @include transform(translateX(0));
      }
    }
  }
  &__label {
    position: relative;
    cursor: default;
    &:before {
      content: "\f078";
      font: normal normal normal 18px/1 FontAwesome;
      text-rendering: auto;
      pointer-events: auto;
      position: absolute;
      top: 0;
      left: 100%;
      height: 100%;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__list {
    position: absolute;
    top: 100%;
    left: -.5em;
    padding: .5em;
    margin: 2px 0 0;
    background-color: $white;
    font-size: 32px;
    line-height: 1.8em;
    opacity: 0;

    @include transform(rotateX(-90deg));
    transform-origin: top center;
    @include transition(all .2s ease-out);

    li {
      opacity: 0;
      @include transform(translateX(-10px));
      @include transition(all .2s ease-out);
      &:nth-child(1) {
        @include transition-delay(.1s);
      }
      &:nth-child(2) {
        @include transition-delay(.2s);
      }
      &:nth-child(3) {
        @include transition-delay(.3s);
      }
      &:nth-child(4) {
        @include transition-delay(.4s);
      }
      &:nth-child(5) {
        @include transition-delay(.5s);
      }
    }

    a {
      border-bottom-width: 2px;
    }
  }
}

/*
  Go to #
 */

.go-to {
  display: inline-block;
  text-align: center;
  span {
    color: $linkcolor;
    border-bottom: 1px solid $linkcolor;
  }
  img {
    display: block;
    margin: .8em auto 0;
    @extend .animated;
    @extend .infinite;
  }

  &:hover, &:focus {
    img {
      @extend .bounce;
    }
  }
}

/*
  Small link in section block
 */

.section-link {
  position: relative;
  top: -15px;
  border-bottom: 1px solid $linkcolor;
  &:hover, &:focus {
    border-color: $hover;
  }

  @media screen and (min-width: $tab) {
    position: absolute;
    top: 5px;
    right: 30px;
  }
}

/*
  Grayscale img
 */

img.grayscale {
  filter: grayscale(100%);
}

/*
  Brand logo
 */

.brand-logo {
    
  display: block;
  &:hover, &:focus {
    opacity: .75;
  }
  img{
      height: 75px;
  }
}

.cta {
  .ash5 {
    margin-bottom: 0;
  }
}

/*
  (╯°□°）╯︵ ┻━┻
 */

[class*='col'],
[class*='pad'],
.container {
  @extend %clearfix;
}

/*
  Hero
 */

.hero {
  position: relative;
  min-height: 480px;

  @media screen and (min-width: $note) {
    height: 100vh;
    min-height: 720px;
  }

  &__visual {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      background-color: rgba($black, .25);
    }
  }
  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 30px 0;

    @media screen and (min-width: $mob+1) {
      //padding: 150px 30px 50px 0;
      padding: 50px 30px 50px 0;
      width: 50%;
    }
    @media screen and (min-width: $land) {
      //padding: 200px 30px 100px 0;
      padding: 100px 30px 100px 0;
    }

    &-top {
      padding: 0 15px;
      @media screen and (min-width: $mob+1) {
        padding: 0 30px;
      }
    }
    &-bottom {
      margin-top: 30px;
      padding: 0 15px;
      @media screen and (min-width: $mob+1) {
        padding: 0 30px;
      }
      @media screen and (min-width: $note) {
        margin-top: auto;
      }
    }
    h1 a {
      white-space: nowrap;
    }
  }

  &__inner {
    height: 100%;
    @media screen and (min-width: $mob+1) {
      display: flex;
    }
  }

  .container {
    height: 100%;
  }

  &--portrait {
    @media screen and (min-width: $note) {
      height: 75vh;
    }
    .hero__visual {
      position: relative;
      height: auto;
      padding: 30px 15px;
      text-align: right;
      @media screen and (min-width: $mob+1) {
        flex: 1;
        padding: 50px 0;
        a {
          margin-left: auto;
          width: auto;
          height: 100%;
          display: inline-block;
        }
      }
      @media screen and (min-width: $land) {
        padding: 100px 0;
      }
      img {
        display: block;
        position: static;
        width: 66.66%;
        height: auto;
        @media screen and (min-width: $mob+1) {
          margin-left: auto;
          width: auto;
          height: 100%;
        }
      }
      &:before {
        content: none;
      }
    }
    .hero__content {
      flex: 1;
      /*color: $black;
      h1 a, a {
        color: $black;
        border-bottom: 1px solid $black;
        &:hover, &:focus {
          color: $black;
          border-bottom-color: $black;
        }
      }*/
    }
  }
  &--landscape {
    .hero__content {
      color: $white;
      h1 a, a {
        color: $white;
        border-bottom: 1px solid $white;
        &:hover, &:focus {
          color: $black;
          border-bottom-color: $black;
        }
      }
    }
  }
}

/*
  Contacts image
 */

.contacts-image {
  img {
    display: block;
    max-height: 150px;
  }
  span {
    display: block;
    color: $graymedium;
    font-size: 12px;
    line-height: 1;
    margin: .875em 0;
  }
  &--large {
    img {
      max-height: 200px;
    }
  }
}

/*
  Jumbotron
 */

.jumbotron {
  position: relative;
  display: flex;
  height: calc(100vh - 98px);
  min-height: 30rem;
  @media screen and (min-width: $mob+1) {
    height: calc(100vh - 110px);
  }
  &__visual {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-inner {
      max-width: 50%;
      color: $white;
      padding: 30px 0;
      @media screen and (min-width: 960px) {
        padding: 90px 0;
      }
      .ash2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: .375em;
      }
      a {
        color: $white;
        border-bottom-color: $white;
      }
    }
    &--top-left {
      justify-content: flex-start;
      align-items: flex-start;
    }
    &--top-center {
      justify-content: flex-start;
      align-items: center;
    }
    &--top-right {
      justify-content: flex-start;
      align-items: flex-end;
    }
    &--bottom-left {
      justify-content: flex-end;
      align-items: flex-end;
    }
    &--bottom-center {
      justify-content: flex-end;
      align-items: flex-end;
    }
    &--bottom-right {
      justify-content: flex-end;
      align-items: flex-end;
    }
    &--left {
      justify-content: center;
      align-items: flex-start;
    }
    &--center {
      justify-content: center;
      align-items: center;
    }
    &--right {
      justify-content: center;
      align-items: flex-end;
    }
  }
}

.slider-overflow {
  .slick-list {
    margin: 0 -15px;
    overflow: visible;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      width: 50vw;
      z-index: 1;
    }
    &:before {
      right: 85%;
      background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 25%);
      @media screen and (max-width: $tab+1) {
        display: none;
      }
    }
    &:after {
      left: 85%;
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 25%);
      @media screen and (max-width: $tab+1) {
        display: none;
      }
    }
  }
  .slick-track {
    display: flex;
    align-items: flex-end;
  }
  .slick-prev.slick-prev {
    left: -6em;
  }
  .slick-next.slick-next {
    right: -6em;
  }
}

.newsletter-popup {
  position: relative;
  background-color: $lightbg;
  padding: 60px;
  width: auto;
  max-width: 640px;
  margin: 30px auto;
}

main {
  overflow-x: hidden;
}

@media screen and (max-width: $mob) {
  .template-home {
    overflow: visible !important;
    .mfp-bg,
    .mfp-wrap {
      display: none;
    }
  }
}

#projects .slider-overflow .slick-list {
  &:before {
    right: 100%;
  }
  &:after {
    left: 100%;
  }
}