/*
	-----------------------------------------
	Forms
	-----------------------------------------
*/

$placeholderColor: $titlecolor;
$inputBorderColor: $linkcolor;
$inputBackgroundColor: $white;
$inputPads: 15px 20px;

/* placeholder styling */
::-webkit-input-placeholder {
  color: $placeholderColor;
}

:-moz-placeholder { /* Firefox 18- */
  color: $placeholderColor;
}

::-moz-placeholder { /* Firefox 19+ */
  color: $placeholderColor;
}

:-ms-input-placeholder {
  color: $placeholderColor;
}

input, textarea {
  @include typo-text;
}

input[type="text"], input[type="email"], input[type="tel"], input[type="search"], input[type="number"], textarea, select {
  -webkit-appearance: none;
  -moz-appearance: none;

  &.error {
    border-color: #e20000;
  }
  &:focus {
    outline: 0;
  }
}

input[type="text"], input[type="email"], input[type="tel"], input[type="search"], input[type="number"] {
  display: block;
  background: $inputBackgroundColor;
  padding: $inputPads;
  @include border-radius(0);
  width: 100%;
  border: 1px solid $linkcolor;
  color: $textcolor;

  &:focus {
    outline: 0;
    border-color: rgba(34, 36, 38, 0.35)
  }
}

.ui.selection.dropdown, select {
  width: 100%;
  background: #fff;
}

.ui.selection:active, .ui.selection:focus, .ui.selection.active.dropdown:hover, .ui.selection.active.dropdown .menu, .ui.selection.active.dropdown {
  border-color: rgba(34, 36, 38, 0.35) !important;
}

textarea {
  border: 1px solid $linkcolor;
  background: $inputBackgroundColor;
  padding: $inputPads;
  @include border-radius(0);
  width: 100%;
  height: 200px;

  &:focus {
    outline: 0;
    border-color: rgba(34, 36, 38, 0.35)
  }
}










form {
  fieldset {
    width: 100%;
    padding: 0;
    margin-bottom: 1.5em;
  }
}

.feedbackcontainer {
  .formfeedback {
    margin: 0;
    padding: 20px;
    background-color: $graylight;
    border: 1px solid $graydark;
    font-size: 14px;

    &.error {
      color: #e20000;
      background-color: #fdebeb;
      border: 1px solid #e20000;
    }
    &.success {
      color: #009688;
      background-color: #edf3f2;
      border: 1px solid #009688;
    }
  }
}







fieldset {
  > label {
    display: block;
    font-size: 20px;
    //padding-left: 20px;
    font-weight: 300;
    margin-bottom: .2em;
  }
}







/*
  Checkbox
 */

.checkbox {
  position: relative;
  padding-left: 30px;
  line-height: $line-height-main;

  label {
    position: absolute;
    top: 3px;
    left: 0px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border: 1px solid $inputBorderColor;
      background-color: $inputBackgroundColor;
    }
    &:after {
      content: "\f00c";
      font-family: FontAwesome;
      position: absolute;
      top: 0;
      left: 0;
      width: 22px;
      height: 22px;
      text-align: center;
      font-size: 10px;
      line-height: 20px;
      color: $placeholderColor;
      opacity: 0;
      @include transition(all .4s ease);
    }
  }
  input {
    opacity: 0;
    height: 1px;
    width: 1px;
    position: absolute;
    top: 0;
    left: 0;
    &:disabled {  }
    &:checked {
      + label:after { opacity: 1; }
    }
  }
  &:hover {
    label:after { opacity: .4; }
  }
}

/*
    Select
 */

.select {
  background-color: $white;
  border: 1px solid $inputBorderColor;
  position: relative;
  height: 52px;
  min-width: 160px;
  
  select {
    height: 100%;
    width: 100%;
    border: none;
    padding: 0 40px 0 20px;
    font-weight: 300;
    @include border-radius(0);
    @include appearance(none);
  }
  &:before {
    content: "\f078";
    font-family: FontAwesome;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    //z-index: 1;
    color: $placeholderColor;
    font-size: 14px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }
}

/*
select{
  background-color: $white;
  border: 1px solid $inputBorderColor;
  border-radius: 0px !important ;
  -webkit-border-radius: 0px !important;
  position: relative;
  height: 52px;
  min-width: 160px;
  padding: 0 20px !important;
}*/
